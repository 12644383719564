import React from "react"
import Container from "../components/common/Container"
import Layout from "./../components/layouts/Default"
import lautenschlagerLogo from "../media/webdesigner-kassel-lautenschlager-logo.png"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Impressum" desc="">
      <Container>
        <div className="prose">
          <h1>Impressum</h1>
          <h2>Angaben gemäß § 5 TMG</h2>
          <p>
            Peter Scherbuk
            <br />
            Naturblick Verlag
            <br />
            Meißner Landstraße 138 b<br />
            01157 Dresden
          </p>

          <h2>Kontakt</h2>
          <p>
            Telefon: +49 (0)351 . 850 72 129
            <br />
            Telefax: +49 (0)351 . 442 63 49
            <br />
            Mobil: +49 (0)173 . 2 6666 40 (auch über Whatsapp)
            <br />
            E-Mail: verlag@naturblick.com
          </p>

          <h2>Umsatzsteuer-ID</h2>
          <p>
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            <br />
            DE 229642928
          </p>

          <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
          <p>
            Peter Scherbuk <br />
            Meißner Landstraße 138 b<br />
            01157 Dresden
          </p>

          <h2>EU-Streitschlichtung</h2>
          <p>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>

          <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </div>
      </Container>
      <Container>
        <div className="max-w-xl p-2 mx-auto my-16 mt-3 text-center bg-gray-100">
          <p className="mb-1 text-xs">Website erstellt von:</p>
          <a
            href="https://lautenschlager.de/leistungen/webdesign-kassel/"
            target="_blank"
            rel="dofollow noreferrer"
          >
            <img
              src={lautenschlagerLogo}
              title="Webdesign Kassel - Lautenschlager Marketing & Entwicklung"
              alt="Webdesign Kassel - Lautenschlager Marketing & Entwicklung"
              className="mx-auto"
            />
            <br />
            Webdesign Kassel - Lautenschlager Marketing & Entwicklung
          </a>
        </div>
      </Container>
    </Layout>
  )
}
